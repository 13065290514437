/****** DONATION COMPONENT ******/
.component-donation {
	.donate-radio {
		display: none;
	}

	.button {
		margin-right: 8px;
		min-width: 124px;
		text-align: center;

		@include mediaquery(tablet-portrait) {
			min-width: 160px;
		}
	}

	.donation-texts.mobile-hidden {
		@include mediaquery-max(tablet-landscape) {
			display: none;
		}

	}

	.donation-text {
		display: none;

		&.amountSelected {
			display: flex;
		}

		img {
			width: 71px;
			height: auto;
		}

		p {
			width: calc(100% - 87px);
			margin-left: $gap*4;
		}
	}

	.dollar-glyph, .currency-glyph {
		vertical-align: middle;
	}

	.donate-text-input {
		@extend .body2;
		@include placeholder(color('text', 'navy-light'));
		width: 250px;
		display: inline-block;
		margin: 0 0 0 4px;
		padding: 0 0 5px 0;
		height: auto;
	}
}