/****** IMAGE COMPONENT ******/
.component-image {
	position: relative;
	// overflow: hidden;

	.image {
		height: 100%;
		width: 100%;
		position: relative;
		
		img {
			@media only screen and (max-width: 1054px) {
				width: calc(100% + 30px);
				margin-left: -15px;
			}

			@include mediaquery(tablet-landscape) {
				object-fit: cover;
				height: 100%;
			}
		}
	}
}