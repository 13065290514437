//fonts
@import url('https://fonts.googleapis.com/css?family=Yrsa:400,700');
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');

// Font Mixins
// ======



@mixin sans-serif-regular {
	font-family: "Quicksand", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}

@mixin sans-serif-italic {
	font-family: "Quicksand", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
}

@mixin sans-serif-semi {
	font-family: "Quicksand", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin sans-serif-bold {
	font-family: "Quicksand", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 700;
}

@mixin sans-serif-mono {
	font-family: "Quicksand", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin serif {
	font-family: "Yrsa", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}

@mixin serif-bold {
	font-family: "Yrsa", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 700;
}


// Grid and Responsive
// ===================
$gap						: 4px;
$site-width					: 1024px;
$nav-height					: 60px;
$nav-height-scrolled		: 100px;
$nav-height-desktop			: 100px;

$tablet-portrait-breakpoint	: 768px;
$tablet-landscape-breakpoint: 1024px;
$desktop-breakpoint			: 1200px;
$large-desktop-breakpoint	: 1440px;
$nav-breakpoint				: 1024px;

// Colors
// ======
$brand-color-key: 'red' !default;
$brand-colors: (
	'debug': (
		'red': 				#f00
	),
	'background': (
		'white':			#FFFEFB,
		'navy': 			#021B48,
		'pale-orange': 		#FFF1E0,
	),
	'text':(
		'navy':					#021B48,
		'navy-hover':			rgba(2,27,72, .7),
		'navy-medium':			rgba(2,27,72, .87),
		'navy-light-hover':		rgba(2,27,72, .4),
		'navy-light':			rgba(2,27,72, .6),
		'orange':				#FB9654,
		'orange-hover':			#FF7F2B,
		'white':				#FFFFFF,
		'white-hover':			rgba(255, 255, 255, .6),
		'turquoise':			#0AA9C4,
		'turquoise-hover':		#52C6D9,
		'pale-gray':			rgba(255, 255, 255, .87)
	),
	'social':(
		'facebook':			#3b5998,
		'twitter':			#1da1f2
	),
	'misc':(
		'black-opacity': 	rgba(0, 0, 0, .82)
	)
);

// Standard colors
// ===============
$white: #FFFFFF;
$black: #000000;

@function color($key: 'debug', $variant: $brand-color-key) {
	$color: null;
	$color-spectrum: map-get($brand-colors, $key);

	@if $color-spectrum {
			$color: map-get($color-spectrum, $variant);
	}

	@return $color;
}

// Utility variables
// ===============

$positions: top, bottom, left, right;
$screensizes: mobile, tablet-portrait, tablet-landscape, desktop, xl, 2xl;

// Generate background and text color classes based on the color map.
//
// Classes are named as follows:
//
// 		.background-key-variant
// 		.color-color-key-variant
//
// For example:
//
// 		.background-green-base
// 		.color-color-high-textDull
//
@each $key, $map in $brand-colors {
	@each $variant, $color in $map {

		.component-theme-#{$key}-#{$variant} {
			background: #{$color};
		}

		.color-#{$key}-#{$variant} {
			color: #{$color};
		}

		.row-text-white {
			.color-#{$key}-#{$variant} {
				color: #fff;
			}
		}

		.row-text-black {
			.color-#{$key}-#{$variant} {
				color: #021B48;
			}
		}
	}
}



// Responsive Font Size Map
// ========================
// $font-size-headline1: (
// 	null  : 48px,
// 	768px : 48px,
// 	1024px: 72px,
// 	1025px: 72px
// );
