/****** STORIES COMPONENT ******/
.component-stories {
	position: relative;

	.stories {
		@include mediaquery(tablet-portrait) {
			@include flexbox(flex);
			@include flexbox-justify-content(space-between);
			@include flexbox-wrap(wrap);
		}

		&.stories-2 {
			@include flexbox-justify-content(center);
		}

		&.stories-1 {
			display: block;
		}
	}

	.story-item {
		width: 100%;
		margin: 0 auto $gap*5;

		@include mediaquery(tablet-portrait) {
			width: calc(33% - 24px);
			margin: 0 0 $gap*5;
		}

		@include mediaquery(tablet-landscape) {
			max-width: 293px;
		}

	}

	.story-image {
		border-radius: 4px;
		overflow: hidden;
		height: 0;
		padding-bottom: 70%;
		position: relative;

		img {
			height: 100%;
			width: auto;
			display: block;
			position: absolute;
			left: -50%;
			right: -50%;
			margin: 0 auto;
		}
	}

	.stories-2 {
		.story-item {
			@include mediaquery(tablet-portrait) {
				margin-right: 72px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.stories-1 {
		.story-item {
			@include mediaquery(tablet-portrait) {
				width: 100%;
				margin: 0 0 $gap*5;
				max-width: none;
				@include flexbox(flex);
				@include flexbox-justify-content(space-between);
				@include flexbox-align-items(center);
			}
		}

		.story-image {
			@include mediaquery(tablet-portrait) {
				width: 62%;
				height: 0;
				padding-bottom: 39.5%;
				margin-right: $gap*15;

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.story-content {
			@include mediaquery(tablet-portrait) {
				width: calc(38% - 60px);
			}
		}
	}

}