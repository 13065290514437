.single {
	
	.single-header {
		max-width: 854px;
		margin: 0 auto;	
	}
	
	.single-header-image {
		overflow: hidden;
	}


}
