//Margin vars
//creates classes based on variables
//adding a screensize of mobile will start at 0px wide. if the margin is the same across screensizes, use "mobile"
@each $screensize in $screensizes{
	@each $position in $positions {
		@for $i from 1 through 400 {
			@if $i % $gap == 0 {
				.margin-#{$screensize}-#{$position}-#{$i} { 
					@include mediaquery(#{$screensize}) {
						margin-#{$position}: #{$i}px;
					}
				}
			}
		}
	}
}