.main-header {
	background-color: color('background', 'navy');
	height: $nav-height;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1001;
	@include transition(top .3s ease-out);

	@include mediaquery(desktop) {
		height: $nav-height-desktop;

		.hideNav & {
			top: -100px;
		}
	}

	.header-inner {
		max-width: 1193px;
		margin: 0 auto;
		position: relative;
	}

	.hamburger-wrapper {
		background-color: #0F2C61;
		height: $nav-height;
		width: $nav-height;
		position: absolute;
		top: 0;
		left: 0;

		@include mediaquery(desktop) {
			display: none;
		}
	}

	.logo-wrapper {
		width: 97px;
		height: 42px;
		position: absolute;
		left: 70px;
		top: 9px;

		@include mediaquery(desktop) {
			left: 20px;
			width: 162px;
			height: 70px;
			top: 15px;
		}

		@include mediaquery(xl) {
			left: 0;
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
		}

		.dreambig-logo {
			width: 97px;
			height: 42px;

			@include mediaquery(desktop) {
				width: 162px;
				height: 70px;
			}
		}
	}

	.donate-button {
		position: absolute;
		right: 10px;
		top: 6px;

		@include mediaquery(desktop) {
			top: 17px;
			right: 20px;
		}

		@include mediaquery(xl) {
			right: 0;
		}
	}

	.social-media, p.caption {
		@include mediaquery(desktop) {
			display: none;
		}
	}

	.main-nav {
		@include mediaquery-max(desktop) {
			width: 100%;
			background: color('background', 'navy');
			height: 0;
			max-height: 0;
			position: absolute;
			overflow: hidden;
			top: $nav-height;
			left: 0;
			@include transition(max-height .3s ease-in);
			
			.navOpen & {
				padding-top: $gap*15;
				height: calc(100vh - 60px);
				max-height: 100vh;
			}
		}

		.menu-items {
			margin-bottom: $gap*12.5;

			@include mediaquery(desktop) {
				text-align: right;
				width: calc(100% - 235px);
				margin-right: 235px;
				padding-top: $gap*10;
				margin-bottom: 0;
			}

			@include mediaquery(xl) {
				width: calc(100% - 215px);
				margin-right: 215px;
			}
		}

		.menu-item {
			@extend .headline5;
			color: color('text', 'pale-gray');
			text-align: center;
			margin-bottom: $gap*5;

			@include mediaquery(desktop) {
				font-size: 14px;
				line-height: 1.43;
				letter-spacing: 1px;
				margin-bottom: $gap*4;
				margin:0 $gap*5;
				text-transform: uppercase;
				display: inline-block;
				@include sans-serif-bold;

				&#menu-item-28 {
					display: none;
				}
			}
		}
	}
}