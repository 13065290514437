/**** Global Text Styles ****/
address {

}

a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	letter-spacing: inherit;

	@include mediaquery(desktop) {
		&:hover {
		}
	}

}

.full-link {
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

mark {
	background-color: transparent;
}


em, i {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}

hr {
	background: #dddddd;
	border: 0;
	height: 1px;
	margin: 25px auto;
}

p, .body1 {
	font-size: 22px;
	line-height: 1.64;
	margin-bottom: $gap*6;
	@include serif;

	a {
		color: color('text', 'orange');
		@include transition(color .4s ease-out);

		@include mediaquery(desktop) {
			&:hover {
				color: color('text', 'orange-hover');
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.body2 {
	font-size: 20px;
	line-height: 1.4;
	margin-bottom: $gap*4;
	@include serif;

	a {
		color: color('text', 'orange');
		@include transition(color .4s ease-out);

		@include mediaquery(desktop) {
			&:hover {
				color: color('text', 'orange-hover');
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}	
}

.caption {
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 1px;
	margin-bottom: $gap*4;
	text-transform: uppercase;
	@include sans-serif-bold;

}

.read-more {
	display: block;
	font-size: 20px;
	line-height: 1.2;
	color: color('text', 'orange');
	text-transform: uppercase;
	@include serif;
	@include transition(color .3s ease-out);

	@include mediaquery(desktop) {
		&:hover {
			color: color('text', 'orange-hover');
		}
	}
}

span {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
}

u {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
}

strong, b {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	@include serif-bold;
}

th, tr, td {
	color: inherit;
}

/**** Button Styles ****/


.button {
	border-radius: 4px;
	border: 1px solid color('text', 'navy');
	color: color('text', 'navy');
	cursor: pointer;
	display: inline-block;
	font-size: 24px;
	min-height: 48px;
	letter-spacing: 1.6px;
	line-height: 1;
	text-transform: uppercase;
	padding: 18px 40px 16px;
	position: relative;
	@include serif-bold;
	@include transition(color .3s ease-in, background .3s ease-in, border-color .3s ease-in);

	@include mediaquery(desktop) {
		min-height: 64px;
		padding: 25px 58px 23px;
	}

	@include mediaquery(desktop){
		&:hover {
			color: $white;
			background: color('text', 'turquoise-hover');
			border-color: color('text', 'turquoise-hover');
		}

		&:focus {
			color: $white;
			background: color('text', 'turquoise');
			border-color: color('text', 'turquoise');
		}

	}

	&.button-selected {
		color: $white;
		background: color('text', 'turquoise');
		border-color: color('text', 'turquoise');
	}

	&.button2 {
		font-size: 20px;
		line-height: 1.2;
	}

	&.button3 {
		font-size: 18px;
		line-height: 1.1;
	}

	&.button-negative {
		color: color('text', 'navy');
		background: $white;
		border-color: $white;

	}

	&.button-long {
		padding: 13px 30px 9px;

		@include mediaquery(desktop) {
			padding: 21px 34px 19px;
		}
	}

	&.button-orange {
		color: $white;
		background: color('text', 'orange');
		border-color: color('text', 'orange');

		@include mediaquery(desktop){
			&:hover {
				color: $white;
				background: color('text', 'orange-hover');
				border-color: color('text', 'orange-hover');
			}

			&:focus {
				color: $white;
				background: color('text', 'orange-hover');
				border-color: color('text', 'orange-hover');
			}
		}
	}

	&.button-inactive {
		pointer-events: none;
		opacity: .5;
	}

	&.circle-arrow {
		position: relative;
		padding-top: 5px;
		padding-bottom: 5px;

		@include mediaquery(desktop) {
			padding: 15px 40px 21px;
		}

		&:after {
			content: '';
			background-image: url('../images/circle-arrow.png');
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
			width: 24px;
			height: 24px;
			margin-left: 6px;
			position: relative;
			top: 6px;
		}
	}
}


/***** Error & Message Styles *****/
.error {
	@extend .caption;
}



/**** Override & Helper Classes ****/
.align-center {
	text-align: center;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-left-mobile {
	@include mediaquery-max(tablet-landscape) {
		text-align: left;
	}
}

.align-right-mobile {
	@include mediaquery-max(tablet-landscape) {
		text-align: right;
	}
}

.align-center-mobile {
	@include mediaquery-max(tablet-landscape) {
		text-align: center;
	}
}

.no-margin {
	margin: 0;
}

// Use dynamically generated color classes as follows:
//
// Classes:
//
// 		.background-key-variant
// 		.color-color-key-variant
//
// For example:
//
// 		.background-accent-blue
// 		.color-accent-blue
//
.underline {
	// for use in WYSIWYG as <u></u> was deprecated in 4.7+
	color:inherit;
	display: inline;
	font-family: inherit;
	font-size:inherit;
	font-style:inherit;
	font-weight:inherit;
	line-height: inherit;
	text-decoration:underline;
}

.lowercase {
	text-transform: lowercase;
}

.nocase {
	text-transform: none;
}

.uppercase {
	text-transform: uppercase;
}


/**** Headline Styles ****/

.headline1 {
	color: inherit;
	font-size: 36px;
	line-height: 1.3;
	letter-spacing: .5px;
	@include sans-serif-bold;

	@include mediaquery(tablet-landscape) {
		font-size: 56px;
		line-height: 1.14;
	}
}

.headline2 {
	color: inherit;
	font-size: 36px;
	line-height: 1.3;
	letter-spacing: .5px;
	@include sans-serif-bold;

	@include mediaquery(tablet-landscape) {
		font-size: 40px;
	}
}

.headline3 {
	color: inherit;
	font-size: 32px;
	line-height: 1.375;
	letter-spacing: .5px;
	@include sans-serif-bold;

	@include mediaquery(tablet-landscape) {
		font-size: 36px;
		line-height: 1.3;
	}
}

.headline4 {
	color: inherit;
	font-size: 28px;
	line-height: 1.43;
	letter-spacing: 0.5px;
	@include sans-serif-bold;
}

.headline5 {
	color: inherit;
	font-size: 22px;
	line-height: 1.45;
	letter-spacing: 0.5px;
	@include sans-serif-bold;
}

.headline6 {
	color: inherit;
	font-size: 18px;
	line-height: 1.56;
	letter-spacing: 0.5px;
	@include sans-serif-bold;
}

.display-block {
	display: block;
}

.row-text-white {
	color: $white;
}

.row-text-black {
	color: color('text', 'navy');
}

/**** Body Copy ****/
.body-text {
	p {
		color: inherit;
	}
	//inline images
	img, .post-img {
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
		width: auto;

		&.alignleft, &.post-img-left {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;

			@include mediaquery(tablet-portrait) {
				clear: left;
				float: left;
				margin: 0 $gap*2.5 $gap*2.5 0;
			}
		}

		&.alignright, &.post-img-right {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;

			@include mediaquery(tablet-portrait) {
				clear: right;
				float: right;
				margin: 0 0 $gap*2.5 $gap*2.5;

				&.single {
					margin: 0 -10% $gap*2.5 $gap*2.5;
				}
			}
		}

		&.aligncenter, &.post-img-none {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;
		}
	}

	.wp-caption {
		&.aligncenter, &.post-img-none {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;
		}

		&.alignleft {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;

			@include mediaquery(tablet-portrait) {
				clear: left;
				float: left;
				margin: 0 $gap*2.5 $gap*2.5 0;
			}
		}

		&.alignright {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;

			@include mediaquery(tablet-portrait) {
				clear: right;
				float: right;
				margin: 0 0 $gap*2.5 $gap*2.5;

				&.single {
					margin: 0 -10% $gap*2.5 $gap*2.5;
				}
			}
		}
		.wp-caption-text {
			@extend .caption;
			margin-top: $gap*.5;
		}
	}

	blockquote {
		padding: 0 $gap*2;

		@include mediaquery(tablet-landscape) {
			padding: 0 $gap*3;
		}
	}

	cite {
		@extend .body1;
		font-style: italic;
		font-size: 12px;
	}

	table {
		td {
			@extend .body1;
			border: 1px solid color('standard', 'gray');
			padding: $gap*.5;
		}
	}

	//ctas and links
	& + a {
		margin-top: $gap*1.5;
	}

	+ .ctas {
		// Add margin to ctas if it's next to a .body-text div
		margin-top: $gap*1.5;
	}

	.button a {
		color: inherit;
		text-decoration: none;
	}

	a, p a, ul li a, ol li a {
		color: color('text', 'orange');
		@include transition(color .4s ease-out);

		@include mediaquery(desktop) {
			&:hover {
				color: color('text', 'orange-hover');
			}
		}
	}

	//colors
	.row-text-white & {
		color: $white;
	}

	.row-text-black & {
		color: color('text', 'navy');
	}

	//list styles
	ul, ol {
		margin-left: $gap*.75;
		margin-bottom: $gap;

		@include mediaquery(tablet-landscape) {
			margin-left: 85px;
		}

		li {
			@extend .body1;
			color: inherit;
			margin-bottom: $gap*.5;
		}
	}

	ul {
		li {
			list-style-type: disc;
		}
	}

	ol {
		li {
			list-style-type: decimal;
		}
	}

	h1 {
		@extend .headline2;
	}

	h2 {
		@extend .headline3;
	}

	h3 {
		@extend .headline3;

	}

	h4 {
		@extend .headline4;

	}

	h5 {
		@extend .headline5;

	}

	h6 {
		@extend .headline6;

	}
}

/**** Header Tabs ****/ 
.header-tab {
	.header-tab-inner {
		background-color: $black;
		display: inline-block;
		padding: $gap*.5 $gap*2 $gap*.5 $gap*.5;

		@include mediaquery(tablet-landscape) {
			padding: $gap 85px 75px $gap;
			max-width: 100%;
		}

	}

	&.header-tab-small {
		text-align: left;

		.layout-direction-left & {
			text-align: left;
		}

		.component-news-small & {
			text-align: right;
		}

		// @include mediaquery(tablet-landscape) {
		// 	text-align: right;
		// }

		.header-tab-inner {
			padding: $gap*.5 $gap*2 $gap*.5 $gap*.5;

			@include mediaquery(tablet-landscape) {
				padding: 16px $gap*3 16px $gap;
				width: 100%;
				text-align: left;
				
				&.header-tab-no-fill {
					width: auto;
				}
			}

		}


	}

}

/**** CTA Tab ****/
.cta-tab {
	@include clearRow;
	.cta-tab-inner {
		cursor: pointer;
		background-color: $black;
		padding: 8px 17px 8px 10px;
		float: right;
		display: inline-block;
		position: relative;
		overflow: hidden;

		@include mediaquery(tablet-landscape) {
			float: none;
			display: block;
			padding: 22px 20px 20px 20px;	
		}

		@include mediaquery(desktop) {
			&:after {
				content: '';
				height: 100%;
				width: 101%;
				background: color('gradients', 'orange-horiz');
				left: -101%;
				position: absolute;
				top: 0;
				z-index: 1;
				@include transition(left .4s ease-out);
			}

			&:hover {
				a {
					color: black;
				}

				&:after {
					left: 0;
				}
				
			}
		}

		.cta-link {
			&:before {
				display: none;
			}
		}

		a {
			z-index: 2;
			position: relative;
		}
	}

	&.cta-tab-multiple {
		.cta-tab-inner {
			display: block;

			.cta-link {
				display: block;
				margin-bottom: 4px;

				&:last-child {
					margin-bottom: 0;
				}
				
				&:before {
					display: none;
				}
			}
		}
	}
}

