/****** TOPICS COMPONENT ******/
.component-topics {
	.topics {
		@include mediaquery(tablet-portrait) {
			@include flexbox(flex);
			@include flexbox-align-items(stretch);
			@include flexbox-justify-content(center);
			@include flexbox-wrap(wrap);
		}
	}

	.topic {
		border: 1px solid #eaeaea;
		background: $white;
		border-radius: 4px;
		position: relative;
		width: 274px;
		margin: 0 auto $gap*8;

		@include mediaquery(tablet-portrait) {
			margin: 0 $gap*4 $gap*8 0;

			&:nth-child(2n) {
				margin-right: 0;
			}
		}

		@include mediaquery(tablet-landscape) {

			&:nth-child(2n) {
				margin-right: $gap*4;
			}

			&:nth-child(3n) {
				margin-right: 0;
			}
		}

		.topic-image {
			height: 200px;
			overflow: hidden;
			position: relative;

			& > img {
				position: absolute;
				left: -50%;
				right: -50%;
				top: 0;
				margin: 0 auto;
			}
		}

		.topic-title {
			@include flexbox(flex);
			@include flexbox-align-items(center);
			@include flexbox-justify-content(center);
			@include flexbox-wrap(wrap);
			padding: 0 $gap*3;
			height: 120px;

			.caption {
				margin-bottom: 0;
			}
		}

		.topic-icon {
			border-radius: 50%;
			width: 80px;
			height: 80px;
			position: absolute;
			top: 160px;
			left: 0;
			right: 0;
			margin: 0 auto;
			padding: 14px;
			z-index: 1;
			@include flexbox(flex);
			@include flexbox-align-items(center);
			@include flexbox-justify-content(center);
		}
	}
}