/****** PARALLAX COMPONENT ******/
.component-parallax {
	.image-wrapper {
		z-index: -1;
	}

	.parallax-image {
		@include mediaquery(tablet-landscape) {
			position: absolute;
			top: -50px;
			max-width: 768px;
			width: 140%;
		}
	}

	.image-left {
		.parallax-image {
			@include mediaquery(tablet-landscape) {
				left: calc(((100vw - 1024px)/2)*-1);
			}
		}
	}

	.image-left-wide {
		.parallax-image {
			@include mediaquery(tablet-landscape) {
				left: calc(((100vw - 854px)/2)*-1);
				max-width: 1024px;
			}
		}
	}

	.image-right {
		.parallax-image {
			@include mediaquery(tablet-landscape) {
				right: 0;
			}
		}
	}

	.pure-g {

		@include mediaquery(tablet-landscape) {
			margin-left: calc((100vw - 1024px)/2);
			width: calc(100vw - ((100vw - 1024px)/2));
	
		}

		&.text-center {
			@include mediaquery-max(tablet-landscape) {
				margin-top: 60px;
			}
			
			@include mediaquery(tablet-landscape) {
				margin-left: calc((100vw - 854px)/2);
			}
		}

		&.text-left {
			@include mediaquery-max(tablet-landscape) {
				@include flexbox-direction(column-reverse);
				@include flexbox(flex);
			}
		}

		&.text-right {
			@include mediaquery-max(tablet-landscape) {
				@include flexbox-direction(column-reverse);
				@include flexbox(flex);
			}
		}
	}

	.text-wrapper {
		padding-left: $gap*5;
		padding-right: $gap*5;

		.parallax-text {
			@include mediaquery(tablet-landscape) {
				max-width: 595px;
			}
		}
	}

	.text-center {
		.parallax-text {
			@include mediaquery(tablet-landscape) {
				max-width: 682px;
			}

			@include mediaquery(desktop) {
				margin-left: 170px;

			}
		}
	}
}