/**** Global Iconography ****/
.share-button {
	border-radius: 4px;
	color: $white;
	display: inline-block;
	height: 56px;
	padding: 14px $gap*14 0;
	font-size: 14px;
	@include transition(opacity .3s ease-out);

	@include mediaquery(desktop) {
		&:hover {
			opacity: .8;
		}
	}

	&.share-facebook {
		background-color: color('social', 'facebook');
	}

	&.share-twitter {
		background-color: color('social', 'twitter');
	}
}

.social-media {
	text-align: center;

	@include mediaquery(tablet-landscape) {
		text-align: right;
	}
}

.social {
	border-radius: 50%;
	border: 2px solid $white;
	color: $white;
	display: inline-block;
	font-size: 14px;
	height: 32px;
	margin: 0 $gap;
	opacity: 1;
	padding-top: 7px;
	text-align: center;
	width: 32px;
	@include transition(opacity .3s ease-out);

	@include mediaquery(desktop) {
		&:hover {
			opacity: .7;
		}
	}

	&.social-large {
		@include mediaquery-max(desktop) {
			width: 40px;
			height: 40px;
			font-size: 18px;
			margin: 0 $gap*2.5;
			padding-top: 9px;
		}
	}
}

.play {
	border-width: 19px 0 19px 38px;
	border-style: solid;
	border-color: transparent transparent transparent #ffffff;
	cursor: pointer;
	height: 0;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	top: calc(50% - 19px);
	width: 0;
	z-index: 12;
}

.arrow {
	font-size: 14px;
	line-height: 1.1;
	letter-spacing: 0;
	color: $white;
	cursor: pointer;
	@include sans-serif-bold;

	&:after {
		content: '\2192';
		display: inline-block;
	}

	&.arrow-prev {
		@include transform(rotate(-180deg));
	}
}

.hamburger {
	background-color: transparent;
	cursor: pointer;
	display: block;
	height: 18px;
	border-top: 1px solid color('text', 'pale-gray');
	position: absolute;
	right: 20px;
	top: 23px;
	width: 24px;
	z-index: 100;
	@include transition(background-color .2s ease-in);

	@include mediaquery(desktop) {
		display: none;
	}

	&:after, &:before {
		background-color: color('text', 'pale-gray');
		content: '';
		height: 1px;
		left: 0;
		position: absolute;
		top: 6px;
		width: 100%;
		@include transition(all .2s ease-in);
	}

	&:after {
	  top: 14px;
	}

	.navOpen & {
		border-top: 0;

		&:after {
			top: 8px;
			@include transform-rotate(45deg);
		}

		&:before {
			top: 8px;
			@include transform-rotate(-45deg);
		}

	}
}


.close {
	cursor: pointer;
	display: block;
	width: 40px;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 0;
	z-index: 100;

	&:after, &:before {
		content: '';
		position: absolute;
		background-color: $black;
		height: 2px;	
		width: 18px;		
	}

	&:after {
		top: 19px;
		left: 11px;
		@include transform-rotate(45deg);
	}

	&:before {
		top: 19px;
		left: 11px;
		@include transform-rotate(-45deg);
	}

	&.close-white {

		&:after, &:before {
			background-color: $white;
		}
	}

	&.close-small {
		@include transform(scale(.5));
	}
}

.arrow-link {
	left: 0;
	position: relative;
	@include transition(left .4s ease-out);

}

.pdf {
	cursor: pointer;
	font-size: 12px;
	letter-spacing: -0.7px;
	line-height: 1.3;
	padding-top: $gap*2;
	position: relative;
	display: inline-block;
	text-align: center;
	@include sans-serif-mono;

	&:before {
		content: '\2192';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		font-size: 30px;
		@include sans-serif-bold;
		@include transform-rotate(90deg);
		@include transition(top .4s ease-out);
	}

	@include mediaquery(desktop) {
		&:hover:before {
			top: 3px;
		}
	}
}



.download-inner {
	cursor: pointer;
	
	@include mediaquery(desktop) {
		&:hover {
			.arrow-link {
				left: 3px;
			}
			
			.pdf:before {
				top: 3px;
			}
		}
	}
}
// Accessibility trick to hide span nearby with aria values
.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
	position: absolute;
    width: 1px;
}
