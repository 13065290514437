/****** BOXES COMPONENT ******/
.component-boxes {
	position: relative;

	.body-text {
		max-width: 854px;
		margin-right: auto;
		margin-left: auto;
	}

	.boxes {
		@include flexbox(flex);
		@include flexbox-justify-content(center);
		@include flexbox-align-items(flex-start);
		@include flexbox-wrap(wrap);
	}

	.box-item {
		width: 100%;

		@include mediaquery(tablet-portrait) {
			width: calc(33% - 76px);
			margin-right: 100px;

			&:nth-child(3n) {
				margin-right: 0;
			}
		}

		.box-icon-wrapper {
			height: 0;
			padding-bottom: 37%;
			overflow: hidden;
			position: relative;
			width: 70%;
			margin-right: auto;
			margin-left: auto;
			max-width: 132px;

			@include mediaquery(tablet-landscape) {
				width: 50%;
			}
		}

		.animated-icon, .box-icon {
			width: auto;
		    height: auto;
		    position: absolute;
		    top: 0;
		    left: -50%;
		    right: -50%;
		    margin: 0 auto;
		    max-width: 100%;
		    max-height: 100%;
		}

	}

	.row-text-white & {
		p {
			color: color('text','pale-gray');
		}
	}

	.boxes-4 {
		.box-item {
			@include mediaquery(tablet-portrait) {
				width: calc(25% - 38px);
				margin-right: 50px;

				&:nth-child(4n) {

				}

				&:nth-child(4n) {
					margin-right: 0;
				}
			}
		}
	}
}