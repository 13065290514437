/**** Form global styles ****/

input {
	@extend .caption;
	border: 0;
	border-bottom: 1px solid color('background', 'navy');
	height: 44px;
	padding: $gap*3 0;
	text-transform: uppercase;
	width: 100%;
	@include placeholder(color('text', 'navy'));
}

label {
	@extend .caption;
	text-transform: uppercase;
	display: block;
}

textarea {
	@extend .body1;
	color: color('text', 'navy');
	padding: $gap*3 $gap*5;
	border-radius: 4px;
	border: 1px solid #C2C7CF;
	margin-bottom: $gap*10;
	width: 100%;
	@include placeholder(color('text', 'navy-light'));

	&:last-child {
		margin-bottom: $gap*10;
	}
}

input[type="submit"] {
	display: inline-block;
	width: auto;
}

.dropdown-wrapper {
	margin-bottom: $gap*4;

	select {
		display: none;
	}
	
	ul.dropdown {
		margin: 0;
		max-height: 58px;
		overflow: hidden;
		border-radius: 4px;
		border: 1px solid #C2C7CF;

		&.openDropdown {
			max-height: 500px;
		}


		li {
			@extend .body1;
			list-style: none;
			margin: 0;
			padding: 0;
			color: color('text', 'navy-light');
			padding: $gap*3 $gap*5 0;
			cursor: pointer;

			&:first-child {
				padding: $gap*3 $gap*5;
			}

			&:nth-child(2) {
				padding-top: $gap*5;
				border-top: 1px solid #C2C7CF;
			}

			&:last-child {
				padding-bottom: $gap*3;
			}

		}
	}
}



//CF7 styles
.wpcf7-checkbox {

	label {
		margin-bottom: $gap*10;
	}

	.wpcf7-list-item-label {
		@extend .body2;
		cursor: pointer;
		display: block;
		padding-left: $gap*10;
		position: relative;
		text-transform: none;
		line-height: 1.4;
		color: color('text', 'navy-medium');
		
		&:before {
			background-size: 14px 13px;
			background-position: center center;
			background-repeat: no-repeat;
			border-radius: 4px;
			border: 2px solid color('background', 'navy');
			content: '';
			cursor: pointer;
			display: inline-block;
			height: $gap*6;
			left: 0;
			position: absolute;
			top: calc(50% - 15px);
			width: $gap*6;
		}
	}

	input[type="checkbox"] {
		display: none;

		&:checked + .wpcf7-list-item-label:before {
			background-image: url('../images/checkbox.png');
		}
	}

}

.screen-reader-response {
	display: none;
}


.subscribe-error {
	margin: $gap*.5 0;
}

.ajax-loader {
	display: none;
}

.wpcf7-form-control-wrap {
	display: block;
	margin-bottom: 0;
	position: relative;

}

.wpcf7-not-valid-tip {
	@extend .caption;
	color: red;
	display: block;
	margin-top: $gap * .25;
	opacity: 1;
	text-transform: none;

	.deadline &, .budget & {
		position: absolute;
		top: 10px;
	}

	.row-text-white & {
		color: red;
	}
}

.wpcf7-response-output {
	@extend .caption;
	color: red;
	clear: both;
	margin-top: $gap*2;
}

.wpcf7-mail-sent-ok {
	color: color('text', 'navy');
}
