//Padding vars
//creates classes based on variables
//adding a screensize of mobile will start at 0px wide. if the padding is the same across screensizes, use "mobile"

//individual paddings, will override the direction from above
// @each $screensize in $screensizes{
// 	@each $position in $positions{
// 	  	@each $padding in $paddings{
// 		    .padding-#{$screensize}-#{$position}-#{$padding}{ 

// 		    	@include mediaquery(#{$screensize}) {
// 		    		padding-#{$position}: #{$padding}px;
// 		    	}
// 		    }
// 		}
// 	}
// }
@each $screensize in $screensizes{
	@each $position in $positions {
		@for $i from 0 through 480 {
			@if $i % $gap == 0 {
				.padding-#{$screensize}-#{$position}-#{$i} { 
					@include mediaquery(#{$screensize}) {
						padding-#{$position}: #{$i}px;
					}
				}
			}
		}
	}
}


.global-action {
	@include mediaquery(tablet-landscape) {
		padding-bottom: 0;
	}
}




