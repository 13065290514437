.main-footer {
	padding: $gap*10 $gap*5;

	@include mediaquery(tablet-landscape) {
		padding: $gap*7.5 $gap*5;
	}

	@include mediaquery(desktop) {
		padding: $gap*7.5 0;
	}

	.menu-items {
		text-align: center;

		@include mediaquery(tablet-landscape) {
			text-align: left;
			display: inline-block;
		}
	}

	.footer-nav {
		@include mediaquery(tablet-landscape) {
			margin-top: 8px;
		}
	}

	.menu-item {
		@extend .caption;
		@extend .color-text-pale-gray;

		@include mediaquery(tablet-landscape) {
			margin-right: $gap*15;
			display: inline-block;
		}
	}

	.social-media {
		margin: $gap*10 auto; 

		@include mediaquery(tablet-landscape) {
			margin: 0 0 $gap*10 0;
		}
	}

	.logo-wrapper {
		text-align: center;

		a {
			display: block;
			width: 100%;
			height: 100%;
		}

		.by-attck {
			width: 141px;
			height: 20px;
			margin: 0 auto;
			display: block;
		}
	}

	p.caption {
		display: inline-block;
	}
}