/****** TEXT COMPONENT ******/
.component-text {
	position: relative;
	// overflow: hidden;

	.body-text {
		max-width: 854px;
		margin: 0 auto;
	}

	.align-left-center & {
		.align-center {
			@include mediaquery-max(tablet-landscape) {
				text-align: left;
			}
		}
	}
}