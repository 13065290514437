* {
	// background: rgba(255, 0, 0, .1);
}

// FADE ME IN - Prevents flashing for fade me in transition
// (js adds opacity 1 once loaded)
h1, h2, h3, h4, h5, h6, p, span, .donate-icon {
	opacity: 0;
	transition: opacity .3s ease-in;

	.dont-fade-me-in &, &.dont-fade-me-in {
		opacity: 1;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	color: color('text', 'navy');
	font-size: px-to-rem(18px);
	height: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	padding-top: $nav-height;
	@include sans-serif-regular;
	@include transition(padding-top .3s ease-out);

	@include mediaquery(desktop) {
		padding-top: $nav-height-desktop;
	}

	&.popupOpen {

		@include mediaquery-max(tablet-portrait) {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
		}
	}

}

.content-container {
	min-height: calc(100vh - 150px);

	@include mediaquery(tablet-landscape) {
		padding-top: 0;
	}

}

.pure-g {
	letter-spacing: inherit;
	@include flexbox-align-content(inherit);
}

img {
	height: auto;
	width: 100%;
	display: block;
}

main.content {
	width: 100%;
}

//===
// Clearfix: contain floats
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//	`contenteditable` attribute is included anywhere else in the document.
//	Otherwise it causes space to appear at the top and bottom of elements
//	that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//	`:before` to contain the top-margins of child elements.
//
.clearfix:before,
.clearfix:after {
	content: " "; // 1
	display: table; // 2
}

.clearfix:after {
	clear: both;
}

.center {
	margin: 0 auto;
}

.component {
	height: 100%;
	width: 100%;

	&.column {
		height: auto;
		width: auto;
	}
}

.component-alignment-bottom {
	@include flexbox(flex);
	@include flexbox-align-items(flex-end);
}

.component-alignment-center {
	@include flexbox(flex);
	@include flexbox-align-items(center);
}

.component-alignment-stretch {
	@include flexbox(flex);
	@include flexbox-align-items(stretch);
}

.component-alignment-top {
	@include flexbox(flex);
	@include flexbox-align-items(flex-start);
	@include flexbox-align-content(flex-start);
}

.component-alignment-content {
	@include flexbox(flex);
	@include flexbox-align-content(stretch);
}


.component-row,
.component-row-inner {
	background-position: center center;
	background-repeat: no-repeat;
	height: auto;
	position: relative;
	width: 100%;
	height: 100%;
}

.component-row {
	@include clearRow;
	z-index: 2;

	@include mediaquery-max(tablet-portrait) {
		// reverse media query to avoid extra css
		.row-reverse-mobile {
			@include flexbox-direction(column-reverse);
			@include flexbox(flex);
			&:after {
				display: none;
			}
		}
	}

	&.zindexTop {
		z-index: 1000;
	}
}

.row-reverse {
	@include flexbox(flex);
	@include flexbox-direction(column-reverse);

	@include mediaquery(tablet-portrait) {
		@include flexbox-direction(row-reverse);
	}
}


.component-row-standard {
	margin: 0 auto;
	max-width: $site-width;
	position: relative;
	width: 100%;
	padding:0 $gap*3.75;

	@include mediaquery(desktop) {
		padding: 0;
	}
}

.component-inner-wrapper{
	max-width: 850px;
	margin-left: auto;
	margin-right: auto;
}

.component-inner-wrapper-large{
	@include mediaquery(tablet-landscape) {
		width: 940px;
		margin-left: 84px;

		&.layout-direction-right {
			margin-left: 0;
			margin-right: 84px;
		}
	}
}

.content-wrapper {
	position: relative;
}


/*** Hide classes ***/
.hideOnMobile {
	display: none;

	@include mediaquery(tablet-portrait) {
		display: initial;
	}
}

.hideOnTabletLandscape {
	display: block;

	@include mediaquery(tablet-landscape) {
		display: none;
	}
}

.hideOnTabletPortrait {
	display: block;

	@include mediaquery(tablet-portrait) {
		display: none;
	}
}

.nav-hidden {
	display: none !important;

	@include nav-break {
		display: inherit !important;
	}
}


.mobile-hidden {
	@include mediaquery-max(tablet-portrait) {
		display: none;
	}

	@include mediaquery(tablet-portrait) {
		display: inline-block;
	}
}

.tablet-hidden {
	@include mediaquery-max(tablet-landscape) {
		display: inline-block;
	}

	@include mediaquery(tablet-landscape) {
		display: none;
	}
}

.desktop-hidden {

	@include mediaquery(desktop) {
		display: none;
	}

}

.showOnDesktop {
	display: none;

	@include mediaquery(desktop) {
		display: block;
	}
}

.showOnTabletLandscape {
	display: none;

	@include mediaquery(tablet-landscape) {
		display: block;
	}
}

// NOTE: (DP) Specificity hack
.hideOnDesktop.hideOnDesktop.hideOnDesktop {
	display: block;

	@include mediaquery(desktop) {
		display: none;
	}
}

.hidden {
	display: none !important;
	visibility: hidden;
}


//===
// Hide visually and from screenreaders, but maintain layout
//
.invisible {
	visibility: hidden;
}


//===
// Hide only visually, but have it available for screenreaders: h5bp.com/v
//
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

//===
// Extends the .visuallyhidden class to allow the element to be focusable
// when navigated to via the keyboard: h5bp.com/p
//
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

/**** Responsive images & images ****/

.responsive-image {
	display: block;
	height: auto;
	left: -50%;
	margin: 0 auto;
	min-height: 100%;
	min-width: 100%;
	position: absolute;
	right: -50%;
	top: 0;
	width: auto;
	z-index: 1;
}

// set padding-bottom in individual style sheet as appropriate
.responsive-wrapper {
	height: 0;
	overflow: hidden;
	position: relative;
}


// Image alignment for single posts
.single {
	.post-img {
		margin-bottom: $gap*3;
		max-width: 100%;
		width: auto;

		@include mediaquery(tablet-portrait) {
			margin-left: -10%;
			max-width: 120%;
			width: 120%;
		}

		&.alignleft, &.post-img-left {
			clear: left;
			float: left;
			margin-right: $gap*.5;
			max-width: 100%;
			width: auto;
		}

		&.alignright, &.post-img-right {
			clear: right;
			float: right;
			margin-left: $gap*.5;
			margin-right: -10%;
			max-width: 100%;
			width: auto;
		}
	}
}

.fill-image {
	left: 0;
	object-fit: cover;
	position: absolute;
	right: 0;
	top: 0;
}

.gallery img {
	// Overrides wysiwyg gallery border. cannot remove all the
	// styles programmatically without causing layout issues.
	border: 0 !important;
}

// Apply to a row or column, and makes the component
// overlap whatever is above it by 100px;
.slide-up-component {
	.component {
		margin-top: -100px;
	}
}


/**** Popups and Overlays ****/
.page-cover-modal {
	position: fixed;
	background-color: white;
	bottom: 0;
	height: 100vh;
	left: 0;
	opacity: 1;
	right: 0;
	top: 0vh;
	width: 100%;
	z-index: 10010;
	@include transition(z-index 1s ease-out, opacity 1s ease-out, background-color 1s ease-out);
	transition-delay: 1s;


	//once dom is loaded, remove modal
	.dom-loaded & {
		z-index: -1;
		opacity: 0;
	}

	//make background blue for homepage
	.home & {
		background-color: color('global', 'navy');
	}

	//display modal after nav is closed
	.leaveNav & {
		z-index: 10010;
		opacity: 1;
	}

	//make background white when leaving homepage
	.leaveNav.home & {
		background-color: white;
	}

}


.video-wrapper,
.embed-youtube {
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;

	iframe, video {
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}

	.video-thumb {
		bottom: 0;
		left: -50%;
		position: absolute;
		right: -50%;
		margin: 0 auto;
		top: 0;
		z-index: 10;	
	}
}

.video-popup, .image-popup {
	height: 100vh;
	overflow: hidden;
	position: fixed;
	width: 100%;
	background-color: $black;
	z-index: 1000;
	display: none;
	top: 0;
	left: 0;

	iframe {
		@include mediaquery(tablet-landscape) {
			bottom: 0;
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 80%;
			margin: 0 auto;
		}
	}

	.image-inner {
		max-width: 100%;
		margin: 0 auto;
		position: relative;

		@include mediaquery(tablet-landscape) {
			max-width: 70%;
		}
	}
}

.form-popup {
	height: 100vh;
	overflow: hidden;
	position: fixed;
	width: 100%;
	background-color: color('misc', 'black-opacity');
	z-index: 1000;
	display: none;
	top: 0;
	left: 0;

	.form-popup-inner {
		background-color: $white;
		position: relative;
		max-width: 596px;
		width: 90%;
		padding: 40px;

		@include mediaquery(tablet-landscape) {
			padding: 85px;
		}
	}

	.popupOpen & {
		@include verticalCenter;
		@include flexbox-justify-content(center);
	}
}

.search-popup {
	height: 100vh;
	overflow: hidden;
	position: fixed;
	width: 100%;
	background-color: $black;
	z-index: 999;
	display: none;
	top: 0;
	left: 0;
	// @include verticalCenter();
	// @include flexbox-justify-content(center);

	.searchOpen & {
		@include verticalCenter();

		@include mediaquery-max(desktop) {
			// z-index: 1000;
		}
	}

	form {
		margin: 0 auto;
		max-width: 680px;
		width: 90%;
	}
}

// NOTE: (DP) Here be dragons. Thou art forewarned
.modal-blackout {
	background: black;
	bottom: 0;
	display: none;
	left: 0;
	opacity: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2;
	@include transition(opacity .5s);

	&.visible {
		opacity: 1;
		@include transition(opacity .5s);
	}

	.show-blackout-modal & {
		display: block;
	}

	// .page-legal & {
		display: block;
		opacity: 1;
	// }

	.dom-loaded & {
		opacity: 0;
	}

	.dom-loaded.dom-has-been-loaded-for-two-seconds & {
		display: none;
	}

	// .window-loaded.window-has-been-loaded-for-two-seconds.show-blackout-modal & {
	.dom-loaded.dom-has-been-loaded-for-two-seconds.show-blackout-modal & {
		display: block;

		&.visible {
			opacity: 1;
		}
	}
}

/**** GDPR ****/
.consent-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 73px;
	z-index: 1000;

	@include mediaquery(tablet-portrait) {
		height: 50px;
	}

	.noConsent & {
		display: block;
	}

	.component-row-standard {
		height: 100%;
	}
}

div#hs-eu-cookie-confirmation {
	background: $black !important;
	border: 0 !important;
	box-shadow: none !important;
	top: auto !important;
	bottom: 0 !important;
	position: fixed !important;

	div#hs-eu-cookie-confirmation-inner {
		background: transparent !important;
		padding: 20px 14px 20px !important;

		p {
			color: $white !important;
			font-size: 11px !important;
			line-height: 1.3;
			letter-spacing: -0.1px;
			margin-bottom: 0;
			margin-right: $gap;
			font-family: "MaisonNeueMono-Regular", Helvetica, Arial, sans-serif !important;


			@include mediaquery(tablet-landscape) {
				font-size: 12px !important;
				line-height: 1.14;
				letter-spacing: -0.13px;
			}

			a {
				text-decoration: underline;

				@include mediaquery(desktop) {
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}

	a#hs-eu-confirmation-button, a#hs-eu-decline-button {
		background-color: $white !important;
		border-radius: 0 !important;
		border: 0 !important;
		font-size: 12px !important;
		line-height: 1.3;
		letter-spacing: -0.5px;
		color: $white !important;
		font-family: "MaisonNeueMono-Regular", Helvetica, Arial, sans-serif !important;
		@include transition(color .4s ease-out, background-color .4s ease-out);

		@include mediaquery(desktop) {
			&:hover {
				background-color: $white !important;
				color: $black !important;
			}
		}
	}

	a#hs-eu-decline-button {
		background-color: $black !important;
		color: $white !important;

		@include mediaquery(desktop) {
			&:hover {
				background-color: $black;
				color: $white;
			}
		}
	}
}

.component-consent {
	min-height: 50px;
	padding: 0 $gap;
	@include verticalCenter;
	@include flexbox-justify-content(space-between);

	@include mediaquery(desktop) {
		padding: 0;
	}

	p {
		font-size: 11px;
		line-height: 1.3;
		letter-spacing: -0.1px;
		margin-bottom: 0;
		margin-right: $gap;

		@include mediaquery(tablet-landscape) {
			font-size: 16px;
			line-height: 1.14;
			letter-spacing: -0.13px;
		}

		a {
			text-decoration: underline;

			@include mediaquery(desktop) {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
/**** Misc ****/

.svg-sprite {
	height: 0;
	overflow: hidden;
}

.z-index-3 {
	z-index: 3;
}

.foreground {
	z-index: 1000;
}

.component-global-actions {

	@include mediaquery(tablet-landscape) {
		padding-bottom: 80px;

		.button, .button.circle-arrow {
			position: absolute;
			bottom: -20px;
		}

		.column > div {
			height: 100%;
			position: relative;
			padding-bottom: 60px;
		}

		.newsletter {
			.button {
				bottom: -34px;
			}
		}
	}
}
